import React from "react"
import Layout from "../components/layout"
import Logo from "../components/images/logo"
import LogoMobileSmall from '../components/images/logo-mobile-small'
import Seo from "../components/seo"
import { motion } from 'framer-motion'
import AnimationFadeIn from "../animations/animation-fade-in";
import Toggler from '../components/toggler'

const JobsPage = (props) => (

    <Layout>

        <Seo title="jobs" description="Wir suchen regelmäßig neue Mitarbeiter im Bereich Grafik, Entwicklung, DevOps oder Projektmanagement. Bewirb dich und sei bei unserem jungen Team dabei!"/>
        <h1 className="subpage--headline">jobs</h1>
        <Logo title="ps:dreizehn GmbH" />
        <LogoMobileSmall title="ps:dreizehn GmbH" />
        <div className="content-wrapper">
            <div className="content">
                <motion.div variants={AnimationFadeIn} transition="spring" initial="hidden" animate="visible" exit="exit">
                    <p className="jobs-introduction">
                        Bitte Bewerbung an <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a> senden!
                    </p>

                    <Toggler title="Duales Studium Wirtschaftsinformatik – BA Glauchau ab Oktober (m/w/d)" uniqueId='1'>

                        <p>
                            Wir sind ps:dreizehn aus Limbach-Oberfrohna, eine Shopware-Agentur, die sich auf erstklassige E-Commerce-Lösungen spezialisiert hat. Unsere Mission: Online-Shops zu entwerfen, die sowohl super aussehen als auch technisch perfektioniert sind. Wenn du ein Studienpartner mit Weitblick und spannenden praktischen Einblicken suchst, dann bist du bei uns genau richtig!
                        </p>

                        <br />

                        <h3>Deine Herausforderung:</h3>
                        <ul>
                            <li>Erhalte tiefgehende Einblicke in die Welt des E-Commerce.</li>
                            <li>Spiele eine aktive Rolle in spannenden Projekten zur Entwicklung neuer Online-Shops.</li>
                            <li>Lerne die Tricks der Konfiguration und Wartung unserer Shop-Systeme.</li>
                            <li>Gewinne erste Einblicke in die Frontend-Programmierung und unterstütze bei der Gestaltung benutzerfreundlicher Oberflächen.</li>
                        </ul>
                        <h3>Dein Profil:</h3>
                        <ul>
                            <li>Begeisterung für digitale Technologien und E-Commerce.</li>
                            <li>Erste Erfahrungen in der Programmierung oder großes Interesse daran.</li>
                            <li>Teamgeist, Engagement und die Bereitschaft, Neues zu lernen.</li>
                            <li>Gute kommunikative Fähigkeiten und eine selbstständige Arbeitsweise.</li>
                        </ul>
                        <h3>Was wir dir bieten:</h3>
                        <ul>
                            <li>Ein junges, dynamisches Team, das Wert auf ein entspanntes Arbeitsklima legt.</li>
                            <li>Ein Umfeld, das wächst und Dir Raum für persönliche und berufliche Entwicklung bietet.</li>
                            <li>Vielfältige Möglichkeiten, Deine Ideen und Vorstellungen einzubringen und umzusetzen.</li>
                            <li>Eine enge Zusammenarbeit mit der BA Glauchau, um Theorie und Praxis optimal zu verknüpfen.</li>
                        </ul>

                        <h3>Bereit für den nächsten Schritt?</h3>

                        <p className="jobs-introduction">
                            Bist Du auf der Suche nach einer Herausforderung, die Dich sowohl fachlich als auch persönlich weiterbringt? Dann freuen wir uns auf Deine Bewerbung an <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a>.
                        </p>
                    </Toggler>

                    <Toggler title="Technischer Shopware Spezialist (m/w/d)" uniqueId='2'>

                        <p>
                            Wir sind ps:dreizehn aus Limbach-Oberfrohna, eine Shopware-Agentur, die sich auf erstklassige E-Commerce-Lösungen spezialisiert hat. Unsere Mission: Online-Shops zu entwerfen, die sowohl ästhetisch ansprechend als auch technisch perfektioniert sind. Aktuell suchen wir nach einem talentierten E-Commerce-Enthusiasten. Wenn du glaubst, dass du unser nächster Shopware-Experte sein könntest und den Ehrgeiz hast, gemeinsam mit uns den digitalen Handel zu prägen, möchten wir dich kennenlernen.
                        </p>

                        <br />

                        <h3>Deine Mission:</h3>
                        <ul>
                            <li>Bring unsere Shopware-Shops zum Laufen und halt sie am Puls der Zeit.</li>
                            <li>Halte unsere Shops stets auf der Überholspur, indem du Systemupdates durchführst und sicherstellst, dass alles reibungslos läuft.</li>
                            <li>Halte ein wachsames Auge auf unsere digitalen Plattformen, spüre Fehler auf und setze dein Know-how ein, um sie gekonnt zu beheben.</li>
                            <li>Arbeite eng mit unserem Entwicklungsteam zusammen und werde dabei auch mal zum Shopware-Guru für Kollegen und Kunden.</li>
                            <li>Unterstütze und schule Kunden in technischen Fragen.</li>
                        </ul>
                        <h3>Dein Profil:</h3>
                        <ul>
                            <li>Ein starkes technisches Verständnis mit besonderem Fokus auf Shopware.</li>
                            <li>Die Fähigkeit, sowohl technische Herausforderungen als auch kreative Lösungsansätze mit Bravour zu meistern.</li>
                            <li>Kenntnisse in Webtechnologien wie HTML, CSS und PHP sind von Vorteil, aber nicht zwingend erforderlich.</li>
                            <li>Teamplayer mit Kommunikationstalent und fließenden Deutschkenntnissen, damit wir alle auf einer Wellenlänge sind.</li>
                        </ul>
                        <h3>Was wir dir bieten:</h3>
                        <ul>
                            <li>Ein dynamisches Team und ein entspanntes Arbeitsklima in einer wachsenden Agentur.</li>
                            <li>Attraktive Vergütung, flexible Arbeitszeiten und echte Anerkennung für deinen Beitrag.</li>
                            <li>Weiterbildungsmöglichkeiten, spannende Projekte und ein Team, das dich unterstützt, mit dir feiert und für dich da ist.</li>
                        </ul>

                        <h3>Bereit für das Abenteuer E-Commerce?</h3>

                        <p className="jobs-introduction">
                            Dann sende uns deine Bewerbungsunterlagen inklusive Lebenslauf und erzähle uns, warum du der/die Richtige für uns bist, an <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a>. Wir freuen uns auf deine Bewerbung! 🚀
                        </p>
                    </Toggler>

                    {/*<Toggler title="Sales & Marketing Manager (m/w/d)" uniqueId='1'>

                        <h3>Das solltest du mitbringen:</h3>
                        <ul>
                            <li>
                                Ein kaufmännisches Studium oder eine abgeschlossene kaufmännische Ausbildung, Abschluss im Bereich Marketing, Technik- oder Medienkommunikation oder vergleichbarer Abschluss
                            </li>
                            <li>Vorerfahrungen im Bereich Marketing und Vertrieb </li>
                            <li>Kommunikationsfähigkeit und Teamgeist</li>
                            <li>Hohes Maß an Kommunikationsfähigkeit und Selbstständigkeit</li>
                            <li>Ein hohes Maß an Eigeninitiative und selbstorganisiertem Arbeiten</li>
                            <li>Engagement</li>
                            <li>Lust an und auf Veränderungen</li>
                        </ul>
                        <h3>Das wäre außerdem hilfreich:</h3>
                        <ul>
                            <li>Kenntnisse im Umgang mit Shopware</li>
                            <li>Vorerfahrungem in den Bereichen Onlinemarketing und Social Media </li>
                            <li>Mehrjährige Berufserfahrung</li>
                        </ul>
                        <h3>Aufgaben</h3>
                        <ul>
                            <li>
                                Eingangskanal für Kundenanfragen per Mail oder Telefon
                            </li>
                            <li>Schnittstelle zwischen Entwickler und Kunde</li>
                            <li>Planung und Durchführung verkaufsfördernder Maßnahmen</li>
                            <li>Projektmanagement</li>
                            <li>Überwachung der Social Media Aktivitäten</li>
                        </ul>
                        <h3>Wir bieten</h3>
                        <ul>
                            <li>Persönliche Entwicklungsmöglichkeiten und Raum für Mitgestaltung</li>
                            <li>Ein junges Führungsteam mit Fokussierung auf Wertschätzung und Augenhöhe</li>
                            <li>Möglichkeit der Arbeit im Homeoffice, leistungsgerechte Entlohnung und Benefits</li>
                            <li>Ein modernes Arbeitsumfeld mit New Work Charakter</li>
                        </ul>

                        <p className="jobs-introduction">
                            Bewerbung an <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a>.
                        </p>
                    </Toggler> */}

                    <Toggler title="IT-Systemadministrator (m/w/d)" uniqueId='3'>
                        <h3>Du zeichnest dich aus durch</h3>
                        <ul>
                            <li>Abgeschlossene/s Studium oder Ausbildung im Bereich Informatik (oder vergleichbarer Studiengang / Ausbildungsberuf)</li>
                            <li>Gute Deutsch- und Englischkenntnisse in Wort und Schrift</li>
                            <li>Lösungsorientiertes und strukturiertes Denken</li>
                            <li>Ein hohes Maß an Eigeninitiative und selbstorganisiertem Arbeiten</li>
                            <li>Eine von Wertschätzung geprägte Kommunikation</li>
                        </ul>
                        <h3>Weitere Voraussetzungen:</h3>
                        <ul>
                            <li>Fundiertes Fachwissen in Microsoft Client- und Serverbetriebssystemen, Serveradministration sowie Linux Server</li>
                            <li>Erfahrung in ERP- und CRM-Systemen</li>
                            <li>Kenntnisse im Umgang mit SQL(-Servern)</li>
                            <li>Fähigkeit zum schnellen Erlernen der Funktionsweisen von Systemen sowie Analyse von Fehlermeldungen</li>
                        </ul>
                        <h3>Aufgaben</h3>
                        <ul>
                            <li>Kommunikatives Bindeglied zwischen einzelnen Firmenbestandteilen und z.T. externen Dienstleistern</li>
                            <li>Betreuung der internen und externen Netzwerklandschaften und Anwendungen</li>
                            <li>Sicherstellung des Datenschutzes und der Datensicherheit / Datensicherung der Mitarbeiter</li>
                            <li>Überwachung und permanente Weiterentwicklung der bestehenden Windows-Infrastruktur</li>
                            <li>Administration u.a. von Benutzerverwaltung, Domänencontroller, Active Directory, Exchange Server, Terminalserver</li>
                            <li>Kommunikation mit Dienstleistern für unsere Telefonanlagen</li>
                            <li>Konzeption und Durchführung bei der Neu-Einführung von Hard- und Software sowie Rollout für alle relevanten Arbeitsplätze</li>
                            <li>Unterstützung in Konzeption und Durchführung firmengruppen-interner Entwicklungsprojekte</li>
                            <li>Fehlerbehebung über internes Ticketsystem / Unterstützung der Entwickler bei Behebung von Störungen</li>
                            <li>Administration und Betreuung von Linux-Systemen</li>
                            <li>Fortlaufende Dokumentation der bestehenden IT-Infrastruktur</li>
                        </ul>
                        <h3>Wir bieten</h3>
                        <ul>
                            <li>Mitarbeit in einer jungen und dynamischen Abteilung – startup-ähnliche Atmosphäre, flache Hierarchien</li>
                            <li>Raum und Zeit für persönliche Weiterentwicklung</li>
                            <li>Top-ausgestattete Umgebung mit Freiraum für individuelle Wünsche</li>
                            <li>Bezahlte Weiterbildungen (z.B. Kongresse, Messen, Konferenzen, Schulungen)</li>
                        </ul>

                        <p className="jobs-introduction">
                            Bewerbung an <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a>.
                        </p>
                    </Toggler>

                    {/*<Toggler title="php developer (m/w/d)" uniqueId='2'>
                        <h3>Du zeichnest dich aus durch</h3>
                        <ul>
                            <li>Abgeschlossenes Studium der Informatik, Angewandten Informatik oder vergleichbarer
                                Studiengang
                            </li>
                            <li>Gute Kenntnisse in der objektorientierten Programmierung mit PHP</li>
                            <li>Erfahrungen in Modellierung und Entwicklung von relationalen Datenbanken (MySQL)</li>
                            <li>Administrative Grundkenntnisse im Umgang mit Linux</li>
                            <li>Lösungsorientiertes und stukturiertes Denken</li>
                            <li>Gute Deutschkenntnisse in Wort und Schrift</li>
                        </ul>
                        <h3>Weiterhin von Vorteil wären</h3>
                        <ul>
                            <li>Erfahrungen mit PHP Frameworks (z.B. Yii, Symfony)</li>
                            <li>Kenntnisse in Versionsverwaltung mit Git/GitHub</li>
                            <li>Erfahrungen im Bereich Continous Integration und Test Driven Development</li>
                            <li>Erfahrungen mit Online-Shop-Systemen (z.B. Shopware)</li>
                        </ul>
                        <h3>Aufgaben</h3>
                        <ul>
                            <li>Konzeption und Entwicklung von Modulen und Backend-Systemen für Intranet, Auswertungsportale
                                und Shopsysteme
                            </li>
                            <li>Plugin-Entwicklung für moderne State-of-the-art E-Commerce-Lösungen (Shopware)</li>
                            <li>Anforderungsanalyse, Aufwandseinschätzung, Implementierung, Testing und Dokumentation
                                komplexer Webprojekte
                            </li>
                        </ul>
                        <h3>Wir bieten</h3>
                        <ul>
                            <li>Mitarbeit in einer jungen und dynamischen Abteilung – startup-ähnliche Atmosphäre, flache Hierarchien</li>
                            <li>Raum und Zeit für persönliche Weiterentwicklung</li>
                            <li>Top-ausgestattete Umgebung mit Freiraum für individuelle Wünsche</li>
                            <li>Bezuschussung für Weiterbildungen (z.B. Kongresse, Messen, Konferenzen, Schulungen)</li>
                        </ul>

                        <p className="jobs-introduction">
                            Bewerbung an <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a>.
                        </p>
                    </Toggler>

                    <Toggler title="full stack developer (m/w/d)" uniqueId='3'>
                        <h3>Du zeichnest dich aus durch</h3>
                        <ul>
                            <li>Abgeschlossenes Studium der Informatik, Angewandten Informatik oder vergleichbarer Studiengang</li>
                            <li>Solide Kenntnisse in der objektorientierten Programmierung mit PHP</li>
                            <li>Erfahrungen in Modellierung und Entwicklung von relationalen Datenbanken (MySQL)</li>
                            <li>Sehr gute Kenntnisse in JavaScript, HTML und CSS</li>
                            <li>Lösungsorientiertes und stukturiertes Denken</li>
                            <li>Gute Deutschkenntnisse in Wort und Schrift</li>
                        </ul>
                        <h3>Weiterhin von Vorteil wären</h3>
                        <ul>
                            <li>Kenntnisse im Umgang mit Paketmanagern für JavaScript (z.B. npm, yarn)</li>
                            <li>Erfahrungen mit JavaScript Frameworks (z.B. React, Vue oder Angular)</li>
                            <li>Erfahrungen mit PHP Frameworks (z.B. Symfony, Yii)</li>
                            <li>Kenntnisse in Versionsverwaltung mit Git/GitHub</li>
                            <li>Erfahrungen im Bereich Continous Integration und Test Driven Development</li>
                        </ul>
                        <h3>Aufgaben</h3>
                        <ul>
                            <li>Konzeption und Entwicklung von Modulen für Intranet, Auswertungsportale und Shopsysteme</li>
                            <li>Plugin-Entwicklung für moderne State-of-the-art E-Commerce-Lösungen (Shopware)</li>
                            <li>Anforderungsanalyse, Aufwandseinschätzung, Implementierung, Testing und Dokumentation komplexer Softwareprojekte</li>
                            <li>UI/UX Designs und Umsetzung responsiver Websites, Webshops, Single Page Applications</li>
                        </ul>
                        <h3>Wir bieten</h3>
                        <ul>
                            <li>Mitarbeit in einer jungen und dynamischen Abteilung – startup-ähnliche Atmosphäre</li>
                            <li>Raum für persönliche Weiterentwicklung</li>
                            <li>Top-ausgestattete Umgebung mit Freiraum für individuelle Wünsche</li>
                            <li>Bezuschussung für Weiterbildungen (z.B. Kongresse, Messen, Konferenzen, Schulungen)</li>
                        </ul>
                        <p className="jobs-introduction">
                            Bewerbung an <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a>.
                        </p>
                    </Toggler>*/}

                </motion.div>
            </div>
        </div>
    </Layout>
)

export default JobsPage